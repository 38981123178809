import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space } from 'styled-system'

const Component = styled.div`
  ${space};
  background: ${props => props.theme.color.white};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color.greyShade2};
`

export const Card = ({ p = 4, children, ...rest }) => (
  <Component p={p} {...rest}>
    {children}
  </Component>
)

Card.propTypes = {
  children: PropTypes.node,
  padding: PropTypes.number,
  p: PropTypes.number
}
export default Card
